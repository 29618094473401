<template>
  <!-- 药品信息 -->
  <div class="drug-information">
    <!-- 下拉菜单 -->
    <van-dropdown-menu>
      <van-dropdown-item
        v-model="value1"
        :options="option1"
      />
      <van-dropdown-item
        v-model="value2"
        :options="option2"
        @change="price"
      />
      <van-dropdown-item
        v-model="value3"
        :options="option3"
        @change="distance"
      />
    </van-dropdown-menu>
    <Scroll
      class="Bscroll"
      ref="scroll"
    >
      <div class="content">
        <div class="card">
          <div class="card-head">
            <span style="color:rgb(75, 122, 249);text-decoration:underline">药房</span>
            <span style="color:rgb(86, 185, 243);text-decoration:underline">距离10km</span>
          </div>
          <div class="card-content">
            <ul>
              <li>阿莫西林胶囊</li>
              <li>
                <span>产地:浙江</span>
                <span>规格:1g*6粒</span>
              </li>
              <li>
                <span>有效日期:2023-01-31</span>
                <span>零售价:15.08元</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Scroll>
  </div>
</template>

<script>
import Scroll from '@/components/content/scroll/BScroll'
export default {
  name: "drugInformation",
  data () {
    return {
      value1: 0,
      value2: 'a',
      value3: 'a',
      option1: [
        { text: '全部商品', value: 0 },
      ],
      option2: [
        { text: '默认价格', value: 'a' },
        { text: '价低优先', value: 'b' },
        { text: '价高优先', value: 'c' },
      ],
      option3: [
        { text: '默认距离', value: 'a' },
        { text: '近距离优先', value: 'b' },
        { text: '远距离优先', value: 'c' },
      ],
    };
  },
  components: {
    Scroll
  },
  methods: {
    //价格排序
    price (value) {
      console.log(value)
    },
    //距离排序
    distance (value) {
      console.log(value)
    }
  },
}
</script>

<style lang="less" scoped>
.drug-information {
  .Bscroll {
    overflow: hidden;
    height: calc(100vh - 3.375rem);
    .content {
      margin: auto;
      margin-top: 1rem;
      width: 95vw;
      .card {
        padding: 0.5rem 0;
        border-radius: 0.3rem;
        box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.1);
        .card-head {
          display: flex;
          justify-content: space-between;
          span {
            margin: 0 1rem;
          }
        }
        .card-content {
          border-radius: 0.3rem;
          background-color: rgb(86, 185, 243);
          width: 95%;
          margin: auto;
          margin-top: 0.5rem;
          padding: 0.5rem;
          box-sizing: border-box;
          line-height: 1.7rem;
          ul > li {
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  }
}
</style>